/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { getSrc, GatsbyImage } from "gatsby-plugin-image"
import slugify from "slugify"

const portfolio = `/portfolio/`
const PortfolioCard = ({ data }) => {
  // Portfolio Image
  const hasImage = getSrc(data.frontmatter.featuredImage).includes("spacer.png")
    ? false
    : true
  const PortfolioImage = hasImage
    ? data.frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""
  const potfolioImg = (
    <Link to={portfolio + slugify(`${data.frontmatter.title}`)}>
      <GatsbyImage
        image={PortfolioImage}
        alt={data.frontmatter.title + " - Featured image"}
        sx={portfolioStyles.featuredImage}
      />
    </Link>
  )

  const PortfolioInfo = (
    <div sx={portfolioStyles.PortfolioInfo}>
      {data.frontmatter.title ? (
        <h3 sx={portfolioStyles.portfolioTitle}>
          <Link to={portfolio + slugify(`${data.frontmatter.title}`)}>
            {data.frontmatter.title}
          </Link>
        </h3>
      ) : (
        ""
      )}
      <Link
        sx={{
          variant: "variants.button.primaryLink",
          fontSize: [2, 3],
          fontWeight: "heading",
          mr: 4,
        }}
        to={portfolio + slugify(`${data.frontmatter.title}`)}
      >
        View project <span className="icon">&rarr;</span>
      </Link>
    </div>
  )

  return (
    <div>
      {data.frontmatter.align === "left" ? (
        <article sx={portfolioStyles.portfolioContainer}>
          {potfolioImg}
          {PortfolioInfo}
        </article>
      ) : (
        ""
      )}

      {data.frontmatter.align === "right" ? (
        <article sx={portfolioStyles.portfolioContainer}>
          <div sx={portfolioStyles.orderOne}>{PortfolioInfo}</div>
          <div sx={portfolioStyles.orderTwo}>{potfolioImg}</div>
        </article>
      ) : (
        ""
      )}

      {data.frontmatter.align === "center" ? (
        <article sx={portfolioStyles.portfolioContainerCenter}>
          {potfolioImg}
          {PortfolioInfo}
        </article>
      ) : (
        ""
      )}
    </div>
  )
}

export default PortfolioCard

const portfolioStyles = {
  portfolioContainer: {
    maxWidth: "100%",
    width: "100%",
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr  1fr"],
    justifyContent: "center",
    mb: 6,
    gap: [4, 4, 4, 7],
  },
  orderOne: {
    order: ["2", "2", "1"],
  },
  orderTwo: {
    order: ["1", "1", "2"],
  },
  PortfolioInfo: {
    maxWidth: "75ch",
    pt: [0, 0, 5, 7],
  },
  featuredImage: {
    maxWidth: "100%",
    width: "100%",
    display: "block",
    m: "0",
    borderRadius: "12px",
    border: "1px solid",
    borderColor: "global.borderColor",
  },
  portfolioTitle: {
    mt: 0,
    mb: 4,
    a: {
      fontWeight: "heroHeading",
      fontSize: [4, 4, 5, 6, 7],
      color: "home.headingColor",
      "&:hover": {
        color: "global.primarylinkColor",
      },
    },
  },
  portfolioDecription: {
    fontSize: [3, 3, 3, 4],
    color: "global.textColor",
    mt: 0,
    mb: 5,
  },
  portfolioTag: {
    display: ["table", "flex"],
    span: {
      variant: "variants.tag",
    },
  },
  portfolioContainerCenter: {
    maxWidth: "100%",
    width: "100%",
    display: "grid",
    gridTemplateColumns: ["1fr"],
    textAlign: ["left", "left", "left"],
    mb: 7,
    gap: 4,
    ".gatsby-image-wrapper": {
      maxWidth: "100% !important",
      maxHeight: "100% !important",
    },
  },
}
